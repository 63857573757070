
import { defineComponent } from 'vue';
import moment from 'moment';
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  name: 'Ethnic Challenged Trainee',
  components: {
    Datatable,
    Field,
  },
  mixins: [apiEndpoint],
  data() {
    return {
      moment: '' as any,
      educationLabel: [
        'Bellow Class-5',
        'PSC',
        'JSC',
        'SSC',
        'HSC',
        'Diploma',
        'Bachelor',
        'Masters',
        'PHD',
      ] as any,
      tranches: [] as any,

      TranceData: [] as any,
      start_date: '',
      end_date: '',
      TranceLabel: [] as any,
      TpartnerLabel: [] as any,
      TinstituteLabel: [] as any,
      TCourseTypeLabel: [] as any,
      TCourseGroupLabel: [] as any,
      TDistrictLabel: [] as any,
      TEthnicLabel: [] as any,
      THighestLabel: [] as any,
      TCourseListLabel: [] as any,
      TCourseLabel: [] as any,
      TbatchesLabel: [] as any,
      TethnicLabel: [] as any,
      selectedDistricts: [] as any,
      LicenseData: [],
      selectedTrance: [],
      ethnicGroups: [] as any,
      licenseProvided: [
        {
          id: 1,
          name: 'Yes',
        },
        {
          id: 2,
          name: 'No',
        },
      ],
      religion: '' as any,
      age: '' as any,
      gender: '' as any,
      ethnic_group: '' as any,
      ispoor: '' as any,
      hasemail: '' as any,
      isemployed: '' as any,
      nid: '' as any,
      bcn: '' as any,
      physical_challenge: '' as any,
      bank_account: '' as any,
      highest_class_completed: '' as any,
      updatedArray: [] as any,
      TpartnerData: [] as any,
      CourseTypeData: [] as any,
      CourseGroupData: [] as any,
      CourseListData: [] as any,
      TinstituteData: [] as any,
      TbatchData: [] as any,
      TdistrictData: [] as any,
      genderData: [] as any,
      dropout: '' as any,
      absent: '' as any,
      searchType: '' as any,
      isDropOut: '' as any,
      isAbsent: '' as any,
      tableData: [] as any,
      api_url: '',

      tableHeader: [
        {
          name: 'Photo',
          key: 'photo',
          selected: true,
        },
        {
          name: 'Entity',
          key: 'association',
          sortable: true,

          selected: true,
        },
        {
          name: 'Training Institute',
          key: 't_institute',
          sortable: true,

          selected: true,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,

          selected: true,
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,

          selected: true,
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: true,

          selected: true,
        },
        {
          name: 'Registration Number',
          key: 'reg_num',
          sortable: true,

          selected: true,
        },
        {
          name: 'Reference Number',
          key: 'ref_num',
          sortable: true,

          selected: true,
        },
        {
          name: 'Trainee Name',
          key: 'trainee_name',
          sortable: true,
          selected: true,
          width: '120px',
        },

        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
          selected: true,
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
          selected: true,
        },
        {
          name: 'NID Alternative',
          key: 'nid_alt',
          sortable: true,
          selected: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          selected: true,
        },
        {
          name: 'Date of Birth',
          key: 'dob',
          sortable: true,
          width: '120px',
          selected: true,
        },
        {
          name: 'Present Address',
          key: 'present_add',
          sortable: true,
          selected: true,
        },
        {
          name: 'Present Post Code',
          key: 'pre_postcode',
          sortable: true,
          selected: true,
        },
        {
          name: 'Present District',
          key: 'present_district',
          sortable: true,
          selected: true,
        },
        {
          name: 'Present Upazila',
          key: 'pre_upazila',
          sortable: true,
          selected: true,
        },
        {
          name: 'Permanent Address',
          key: 'permanent_add',
          sortable: true,
        },
        {
          name: 'Permanent Post Code',
          key: 'per_postcode',
          sortable: true,
        },
        {
          name: 'Permanent District',
          key: 'permanent_district',
          sortable: true,
        },
        {
          name: 'Permanent Upazila',
          key: 'per_upazila',
          sortable: true,
        },
        {
          name: 'Home District',
          key: 'home_dist',
          sortable: true,
        },
        {
          name: 'Home Upazila',
          key: 'home_upazila',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Alternative Mobile',
          key: 'alt_mobile',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Religion',
          key: 'religion',
          sortable: true,
        },
        {
          name: 'Ethnic Group',
          key: 'ethnic',
          sortable: true,
        },
        {
          name: 'Highest Class Completed',
          key: 'hcc',
          sortable: true,
        },
        {
          name: 'Highest Class Completed Year',
          key: 'hccy',
          sortable: true,
        },
        {
          name: 'Is Employed',
          key: 'is_employed',
          sortable: true,
        },
        {
          name: 'Year Of Experience',
          key: 'year_of_exp',
          sortable: true,
        },
        {
          name: 'Family Monthly Income',
          key: 'fmi',
          sortable: true,
        },
        {
          name: 'Is Physically Challenged',
          key: 'ipc',
          sortable: true,
        },
        {
          name: 'Challenge Remarks',
          key: 'cr',
          sortable: true,
        },
        {
          name: 'Mother',
          key: 'mother',
          sortable: true,
        },
        {
          name: 'Mother Education Level',
          key: 'mel',
          sortable: true,
        },
        {
          name: 'Mother Occupation',
          key: 'mother_ocu',
          sortable: true,
        },
        {
          name: 'Father',
          key: 'father',
          sortable: true,
        },
        {
          name: 'Father Education Level',
          key: 'fel',
          sortable: true,
        },
        {
          name: 'Father Occupation',
          key: 'f_occupation',
          sortable: true,
        },
        {
          name: 'Father Annual Income',
          key: 'fai',
          sortable: true,
        },
        {
          name: 'Have Family Owned Home',
          key: 'hfoh',
          sortable: true,
        },
        {
          name: 'Have Family Owned Land',
          key: 'hfol',
          sortable: true,
        },
        {
          name: 'Number Of Siblings',
          key: 'nos',
          sortable: true,
        },
        {
          name: 'Bank Name',
          key: 'bank_name',
          sortable: true,
        },
        {
          name: 'Bank Branche Name',
          key: 'bbn',
          sortable: true,
        },
        {
          name: 'Bank Routing No',
          key: 'brn',
          sortable: true,
        },
        {
          name: 'Bank Account Number',
          key: 'ban',
          sortable: true,
        },
        {
          name: 'Account Type',
          key: 'account_type',
          sortable: true,
        },
        {
          name: 'X Bank',
          key: 'x_bank',
          sortable: true,
        },
        {
          name: 'X Mobile banking',
          key: 'x_mobile_banking',
          sortable: true,
        },
        {
          name: 'Mobile Banking Name',
          key: 'mbn',
          sortable: true,
        },
        {
          name: 'Mobile Banking Prefix',
          key: 'mbp',
          sortable: true,
        },
        {
          name: 'Agent Banking Name',
          key: 'abn',
          sortable: true,
        },

        {
          name: 'Transaction Type',
          key: 'transaction_type',
          sortable: true,
        },
        {
          name: ' Batch Start Date',
          key: 'batch_start_date',
          sortable: true,
          width: '120px',
        },
        {
          name: ' Batch End Date',
          key: 'batch_end_date',
          sortable: true,
          width: '120px',
        },
        {
          name: 'Attendance Percentage',
          key: 'att_perc',
          sortable: true,
        },
        {
          name: 'Selected For Stipend',
          key: 'sfs',
          sortable: true,
        },
        {
          name: 'Selection Date',
          key: 'sel-date',
          sortable: true,
        },
        {
          name: 'Bank Verified',
          key: 'bank_ver',
          sortable: true,
        },
        {
          name: 'Verification Date',
          key: 'ver-date',
          sortable: true,
        },
        {
          name: 'Stipend Disbursed',
          key: 'stip-disburse',
          sortable: true,
        },
        {
          name: 'Disbursement Date',
          key: 'disb-date',
          sortable: true,
        },
        {
          name: 'Disbursed Amount',
          key: 'disb_amount',
          sortable: true,
        },
        {
          name: 'Disbursement Remarks',
          key: 'dib_remarks',
          sortable: true,
        },
        {
          name: 'Is Poor',
          key: 'is_poor',
          sortable: true,
        },

        {
          name: 'Stipend Year',
          key: 'sti-year',
          sortable: true,
        },
        {
          name: 'Stipend Quarter',
          key: 'sti-quarter',
          sortable: true,
        },
        {
          name: 'Stipend Start Date',
          key: 'sti-start-date',
          sortable: true,
        },
        {
          name: 'Stipend End Date',
          key: 'sti-end-date',
          sortable: true,
        },
        {
          name: 'Income Certificate',
          key: 'income_cert',
          sortable: true,
        },
        {
          name: 'Trainee Remarks',
          key: 'trainee_remarks',
          sortable: true,
        },
        {
          name: 'Bank Verification Remarks',
          key: 'bank_v_r',
          sortable: true,
        },
        {
          name: 'Dup Enrollment',
          key: 'dup-enroll',
          sortable: true,
        },
        {
          name: 'Allow Dup Enrollment',
          key: 'allow_dup_enroll',
          sortable: true,
        },
        {
          name: 'Eligible Duplicate Courses',
          key: 'eligible_dup_course',
          sortable: true,
        },
        {
          name: 'Stipend Count',
          key: 'sti_count',
          sortable: true,
        },
        {
          name: 'Bill No ',
          key: 'bill_no',
          sortable: true,
        },
      ] as any,
      pdfHeader: [] as any,
      st: [] as any,
      instituteList: [] as any,
      courseType: [] as any,
      courseGroup: [] as any,
      courseList: [] as any,
      batchList: [] as any,
      districtList: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      entityInfos: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
      tableLoad: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranche();
    await this.associationList();
    await this.getCourseTypeList();
    await this.getCourseGroupList();
    await this.getEthnicGroup();
    await this.getDistrict();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.TinstituteData);
      formData.set('course', this.CourseListData);
      formData.set('batch', this.TbatchData);
      formData.set('district', this.TdistrictData);
      formData.set('religion', this.religion);
      formData.set('gender', this.gender);
      formData.set('ethnic_group', this.ethnic_group);
      formData.set('highest_class_completed', this.highest_class_completed);
      formData.set('age', this.age);
      formData.set('ispoor', this.ispoor);
      formData.set('hasemail', this.hasemail);
      formData.set('nid', this.nid);
      formData.set('bcn', this.bcn);
      formData.set('physical_challenge', this.physical_challenge);
      formData.set('bank_account', this.bank_account);

      let data = `${this.VUE_APP_API_URL}/api/trainee/advanceSearchprint`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
           duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.TinstituteData);
      formData.set('course', this.CourseListData);
      formData.set('batch', this.TbatchData);
      formData.set('district', this.TdistrictData);
      formData.set('religion', this.religion);
      formData.set('gender', this.gender);
      formData.set('ethnic_group', this.ethnic_group);
      formData.set('highest_class_completed', this.highest_class_completed);
      formData.set('age', this.age);
      formData.set('ispoor', this.ispoor);
      formData.set('hasemail', this.hasemail);
      formData.set('nid', this.nid);
      formData.set('bcn', this.bcn);
      formData.set('physical_challenge', this.physical_challenge);
      formData.set('bank_account', this.bank_account);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/advanceSearchExcel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'AdvanceSearch.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
           duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.TinstituteData);
      formData.set('course', this.CourseListData);
      formData.set('batch', this.TbatchData);
      formData.set('district', this.TdistrictData);
      formData.set('religion', this.religion);
      formData.set('gender', this.gender);
      formData.set('ethnic_group', this.ethnic_group);
      formData.set('highest_class_completed', this.highest_class_completed);
      formData.set('age', this.age);
      formData.set('ispoor', this.ispoor);
      formData.set('hasemail', this.hasemail);
      formData.set('nid', this.nid);
      formData.set('bcn', this.bcn);
      formData.set('physical_challenge', this.physical_challenge);
      formData.set('bank_account', this.bank_account);
      // console.log(JSON.stringify(this.st));
      await ApiService.post('trainee/advanceSearchpdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    tooltipFormatter(val) {
      console.log(`Age: ${val}`);
      return `Age: ${val}`;
    },
    async getEthnicGroup() {
      await ApiService.get('configurations/ethnic_group/list')
        .then((response) => {
          this.ethnicGroups = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    isdropoutCheck(event) {
      if (event.target.checked) {
        this.isDropOut = -1;
      }
    },
    isabsentCheck(event) {
      if (event.target.checked) {
        this.isAbsent = 0;
      }
    },
    trancheLabelChange(data) {
      // this.getCourseListData();
      this.TranceLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.tranches.length; j++) {
          if (data[i] == this.tranches[j].id) {
            this.TranceLabel.push(this.tranches[j].label);
          }
        }
      }
    },
    trainingPartnerLabelChange(data) {
      // this.getCourseListData();
      this.getInstitute();
      this.TpartnerLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.entityInfos.length; j++) {
          if (data[i] == this.entityInfos[j].id) {
            this.TpartnerLabel.push(this.entityInfos[j].entity_short_name);
          }
        }
      }
    },
    trainingInstituteLabelChange(data) {
      this.getCourseListData();

      this.TinstituteLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.instituteList.length; j++) {
          if (data[i] == this.instituteList[j].id) {
            this.TinstituteLabel.push(this.instituteList[j].short_name);
          }
        }
      }
    },
    async getCourseTypeList() {
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseType = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseGroupList() {
      await ApiService.get('configurations/category_type/list')
        .then((response) => {
          this.courseGroup = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseListData() {
      // this.load = true;
      await ApiService.get(
        'course/filterCourse?entity=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&courseType=' +
        this.CourseTypeData +
        '&courseGroup=' +
        this.CourseGroupData
      )
        .then((response) => {
          this.courseList = response.data.data;
          // this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseTypeLabelChange(data) {
      this.getCourseListData();
      this.TCourseTypeLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseType.length; j++) {
          if (data[i] == this.courseType[j].id) {
            this.TCourseTypeLabel.push(this.courseType[j].type_name);
          }
        }
      }
    },
    tdistrictLabelChange(data) {
      this.TDistrictLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.districtList.length; j++) {
          if (data[i] == this.districtList[j].id) {
            this.TDistrictLabel.push(this.districtList[j].district_name_eng);
          }
        }
      }
    },
    tethnicLabelChange(data) {
      this.TEthnicLabel = [];

      for (let j = 0; j < this.ethnicGroups.length; j++) {
        if (data == this.ethnicGroups[j].id) {
          this.TEthnicLabel.push(this.ethnicGroups[j].group_name);
        }
      }
    },
    highest_class_completed_info(data) {
      this.THighestLabel = [];

      for (let j = 0; j < this.educationLabel.length; j++) {
        if (data == this.educationLabel[j]) {
          this.THighestLabel.push(this.educationLabel[j]);
        }
      }
    },
    courseGroupLabelChange(data) {
      this.getCourseListData();
      this.TCourseGroupLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseGroup.length; j++) {
          if (data[i] == this.courseGroup[j].id) {
            this.TCourseGroupLabel.push(this.courseGroup[j].category_name);
          }
        }
      }
    },
    courseListLabelChange(data) {
      this.getBatches();
      this.TCourseListLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseList.length; j++) {
          if (data[i] == this.courseList[j].id) {
            this.TCourseListLabel.push(this.courseList[j].course_name);
          }
        }
      }
    },

    tbatchLabelChange(data) {
      this.TbatchesLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.batchList.length; j++) {
          if (data[i] == this.batchList[j].id) {
            this.TbatchesLabel.push(this.batchList[j].batch_sequence_number);
          }
        }
      }
    },
    resetData() {
      this.tableData = [] as any;
      this.st = [] as any;
      this.highest_class_completed = '' as any;

      (this.TranceLabel = [] as any),
        (this.TpartnerLabel = [] as any),
        (this.TinstituteLabel = [] as any),
        (this.TCourseTypeLabel = [] as any),
        (this.TCourseGroupLabel = [] as any),
        (this.TCourseListLabel = [] as any),
        (this.TEthnicLabel = [] as any),
        (this.THighestLabel = [] as any),
        (this.TCourseLabel = [] as any),
        (this.TDistrictLabel = [] as any),
        (this.TbatchesLabel = [] as any),
        (this.TpartnerData = [] as any),
        (this.CourseTypeData = [] as any),
        (this.CourseListData = [] as any),
        (this.TbatchData = [] as any),
        (this.CourseGroupData = [] as any),
        (this.TinstituteData = [] as any),
        (this.TbatchData = [] as any),
        (this.genderData = [] as any),
        (this.TdistrictData = [] as any),
        (this.TranceData = [] as any),
        (this.religion = '' as any),
        (this.gender = '' as any),
        (this.ispoor = '' as any),
        (this.hasemail = '' as any),
        (this.nid = '' as any),
        (this.bcn = '' as any),
        (this.physical_challenge = '' as any),
        (this.bank_account = '' as any),
        (this.isemployed = '' as any),
        (this.age = '' as any),
        (this.showall = false);
      this.showTableData = false;
      this.componentTableKey += 1;
    },
    async generatetraineeAdvanceReport() {
      this.tableLoad = true;
      await ApiService.get(
        'trainee/advance-search?entity=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&course=' +
        this.CourseListData +
        '&institute=' +
        this.TinstituteData +
        '&batch=' +
        this.TbatchData +
        '&district=' +
        this.TdistrictData +
        '&religion=' +
        this.religion +
        '&gender=' +
        this.gender +
        '&ethnic_group=' +
        this.ethnic_group +
        '&highest_class_completed=' +
        this.highest_class_completed +
        '&age=' +
        this.age +
        '&ispoor=' +
        this.ispoor +
        '&hasemail=' +
        this.hasemail +
        '&nid=' +
        this.nid +
        '&bcn=' +
        this.bcn +
        '&physical_challenge=' +
        this.physical_challenge +
        '&bank_account=' +
        this.bank_account
      )
        .then((response) => {
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;

          this.componentTableKey += 1;
          this.showTableData = true;
          this.tableLoad = false;
        })
        .then((response) => {
          console.log(response);
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstitute() {
      // this.load = true;
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/filterInstitute?entity=' +
        this.TpartnerData +
        '&institute_info_id=' +
        institute_id
      )
        .then((response) => {
          console.log(response);
          this.instituteList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getBatches() {
      // this.load = true;
      await ApiService.get(
        'batch/multiple-batch-filter?tranche=' +
        this.TranceData +
        '&entity=' +
        this.TpartnerData +
        '&institute=' +
        this.TinstituteData +
        '&course=' +
        this.CourseListData
      )
        .then((response) => {
          console.log(response);
          this.batchList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getData() {
      if (this.TpartnerData.length || this.TranceData.length) {
        await ApiService.get(
          'report/motor-driving-batch?tranche=' +
          this.TranceData +
          '&entity=' +
          this.TpartnerData +
          '&institute=' +
          this.TinstituteData +
          '&batch=' +
          this.TbatchData +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;
            this.componentTableKey += 1;
          })
          .then((response) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Error Found',
          text: 'Tranche or Training Partner Required',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    selectedTableHeader() {
      this.st = Array();

      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() { },
});
